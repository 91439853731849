import React, { useEffect, useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'
import couponsApi from '../../api/coupons'
import { Heading1Bold } from '../../design-system/typography'
import { Button, PageWrapper } from '../../design-system/utils'
import ClubEditor from './CouponEditor'
import HeaderWrapper from './style'
import withAuthorization from '../../components/HOC/withAuthorization'
import CouponsTable from './CouponsTable'
import SearchField from '../../components/SearchField'

function Coupons() {
  const [coupons, setCoupons] = useState(null)
  const [unauthorized, setUnauthorized] = useState(false)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [triggerUpdate, setTriggerUpdate] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    setCoupons([])
    couponsApi
      .getAllCoupons()
      .then((res) => {
        setCoupons(res.data)
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          setUnauthorized(true)
        }
      })
  }, [triggerUpdate])

  const couponGroups = useMemo(() => {
    if (!coupons) return []
    return Object.keys(coupons)
  }, [coupons])

  if (unauthorized) {
    return <Redirect to="/login" />
  }

  return (
    <PageWrapper>
      <HeaderWrapper>
        <Heading1Bold>Coupon</Heading1Bold>
        <Button
          secondary
          type="button"
          onClick={() => setCreateModalOpen(true)}
        >
          + New Coupon
        </Button>
        <SearchField placeholder="Search coupon" onSearch={setSearch} />
      </HeaderWrapper>

      <CouponsTable
        coupons={coupons}
        onUpdate={() => setTriggerUpdate((update) => !update)}
        search={search}
      />
      {createModalOpen && (
        <ClubEditor
          closeModal={() => setCreateModalOpen(false)}
          onUpdate={() => setTriggerUpdate((update) => !update)}
          couponGroups={couponGroups}
        />
      )}
    </PageWrapper>
  )
}

export default withAuthorization(Coupons)
