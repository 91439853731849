import axios from 'axios'
import CouponApiData from '../types/CouponApiData'

const getAllCoupons = () => {
  return axios.get(`/api/coupon/list`, {
    withCredentials: true,
  })
}

const createCoupon = ({
  title,
  code,
  appleCouponCode,
  googleCouponCode,
  maxRedemptions,
  startDateTime,
  endDateTime,
  status,
  mediaSource,
  campaign,
  entitlementDuration,
  group,
}: CouponApiData) => {
  return axios.post(
    '/api/coupon/create',
    {
      title,
      code,
      appleCouponCode,
      googleCouponCode,
      maxRedemptions,
      startDateTime,
      endDateTime,
      status,
      mediaSource,
      campaign,
      entitlementDuration,
      group,
    },
    {
      withCredentials: true,
    }
  )
}

const createCouponsGroup = ({
  title,
  code,
  appleCouponCode,
  googleCouponCode,
  entitlementDuration,
  quantity,
  maxRedemptions,
  startDateTime,
  endDateTime,
  status,
  mediaSource,
  campaign,
}: CouponApiData) => {
  return axios.post(
    '/api/coupon/create-list',
    {
      title,
      code,
      appleCouponCode,
      googleCouponCode,
      maxRedemptions,
      startDateTime,
      endDateTime,
      status,
      mediaSource,
      campaign,
      entitlementDuration,
      quantity,
    },
    {
      withCredentials: true,
    }
  )
}

const deleteCoupon = (couponId) => {
  return axios.delete(`/api/coupon/${couponId}`, {
    withCredentials: true,
  })
}

const couponApi = {
  getAllCoupons,
  createCoupon,
  deleteCoupon,
  createCouponsGroup,
}

export default couponApi
